// $primary: #813b2f;
// $secondary: #fcfaed;
// $dark: #f5f0e1;
// $font: #411b14;
// $primary_shadow: #6d2e22;
// $success: #d7ff85;

// Mudança cores primary, font, primary_shadow;
// $primary: #813b2f;
$primary: #2D3C3C;
$secondary: #fefaf0;
$dark: #f5efe1;
// $font: #411b14;
$font: #2D3C3C;
// $primary_shadow: #6d2e22;
$primary_shadow: #273535;
// $primary_shadow: #131a1a;
$success: #d7ff85;

@import "../../../node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&display=swap");

@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/BasierCircle-Regular.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/BasierCircle-RegularItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/BasierCircle-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/BasierCircle-Medium.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/BasierCircle-MediumItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/BasierCircle-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

body {
  // Mudança font
  font-family: 'Basier Circle', sans-serif;
  color: $font;
}

html {
  scroll-behavior: smooth !important;
}

.text-1 {
  font-size: 3.1em !important;
}
.text-2 {
  font-size: 2.8em !important;
}
.text-3 {
  font-size: 2.48em !important;
}
.text-4 {
  font-size: 2.2em !important;
}
.text-5 {
  font-size: 1.8em !important;
}

.weight-300 {
  font-weight: 300 !important;
}

.weight-400 {
  font-weight: 400 !important;
}

.bold {
  font-weight: bold !important;
}

.h-fit-content {
  height: fit-content !important;
}

.w-fit-content {
  width: fit-content !important;
}

.border-radius {
  border-radius: 10px !important;
}

.noScroll::-webkit-scrollbar {
  display: none !important;
}

.timerAnim {
  animation: timer linear;
}
.timerAnimModal {
  animation: timer linear;
  background: $primary,
}

.error {
  animation: error forwards 0.5s;
}

.pulse {
  animation: pulse forwards 0.5s;
}

.accordion {
  padding: 0;
  margin: 0;

  & .accordion-item {
    border: 0 !important;
    border-radius: 10px !important;
    background-color: $secondary !important;
    color: $font !important;
    font-family: "Nunito", sans-serif !important;
  }

  & .accordion-header {
    border: 0 !important;
    border-radius: 10px !important;
    background-color: $secondary !important;
    outline: 0 !important;
    color: $font !important;
    font-family: "Nunito", sans-serif !important;
  }

  & .accordion-button {
    border: 0 !important;
    border-radius: 10px !important;
    background-color: $secondary !important;
    height: 100px;
    outline: 0 !important;
    box-shadow: none !important;
    color: $font !important;
    font-family: "Nunito", sans-serif !important;
  }

  & .accordion-button:after {
    display: none !important;
    outline: 0 !important;
    color: $font !important;
    font-family: "Nunito", sans-serif !important;
  }

  & .accordion-button:not(.collapsed)::after {
    border: 0 !important;
    outline: 0 !important;
    color: $font !important;
    font-family: "Nunito", sans-serif !important;
  }

  & .accordion-button:focus {
    box-shadow: none !important;
    border: 0 !important;
    outline: 0 !important;
    color: $font !important;
    font-family: "Nunito", sans-serif !important;
  }
}

.spinner {
  animation: rotator 1.4s linear infinite;
}

#loading-bar-spinner.spinner {
  animation: loading-bar-spinner 0.9s linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 225px;
  height: 225px;
  border:  solid 8px transparent;
  border-top-color:  $primary !important;
  border-left-color: $primary !important;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
0%   { transform: rotate(0deg);   transform: rotate(0deg); }
100% { transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px $primary;
    transform: scale(1);
  }
  100% {
    box-shadow: 0 0 0 30px rgba(129, 59, 47, 0);
    transform: scale(1.1);
  }
}

@keyframes error {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(10px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-10px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(8px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-8px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}

@keyframes timer {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.customScroll::-webkit-scrollbar {
  width: 0.5em !important;
}
 
.customScroll::-webkit-scrollbar-thumb {
  background-color: $font !important;
  border-radius: 0.5rem !important;
}

.modal-content{
  background-color: transparent;
  border: none;
}


.pulse-infinite {
  animation: pulse-animation-infinite 2s infinite;
}

@keyframes pulse-animation-infinite {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 16px rgba(0, 0, 0, 0);
  }
}
